@media screen and (min-width: 750px) {
  .container-common{
      width: 98%;
      margin: auto;
  }
  .contact-card {
    flex: 2 58%;
  }
  .container-middle {
    width: 100%;
    margin: auto;
  }
  .services-image {
    height: 430px;
  }
}
@media screen and (min-width: 992px) {
  .container-common{
      width: 90%;
      margin: auto;     
  } 
  .contact-card {
    width: 18em;
  }
  .container-middle {
    width: 90%;
    margin: auto;
  }
  .services-image {
    height: 300px;
  }
  .feature-column {
    height: 450px;
  }
}

body, html {
  font-size: 16px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  background-image: url('https://www.omniecoshield.in/images/background-logo.png');
  background-position: center;
  background-size: 75%;
  background-attachment: fixed;
  background-repeat: no-repeat; 
}

#logoImage {
  width: auto; 
  height: 70px;
  display: inline-block;
}
                          /* NAVIGATION BAR */
.navbar {
  align-items: center;
  background-color: white; 
  margin: 0;
}
.container-common {
  padding: 50px 20px 10px;
}
.container-middle {
  padding: 5px 20px;
}

.brand-logo {
  padding: 0 2%;
  text-decoration: none;
  position: relative;
  cursor: pointer;
}

.nav-item {
  margin: 5px 25px;
}
.navbar-nav a{
  color: black;
  font-size: 1.3rem;
}
.navbar-nav a:hover{
  color:  #7a7a7c;
}
.big-heading {
  font-size: 5rem;
}
.focus:focus {
  color: rgb(169, 165, 165);
}

.nav-link {
  cursor: pointer;
}

.dropdown-item.active, .dropdown-item:active {
   background-color: transparent;
}

.dropdown-item {
  cursor: pointer;
}
                        /* CAROUSEL */
.carousel-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color:white;
  font-weight: bold;
}
                          /* FEATURES ICON */
.features-icon {
   color:#47d94a;
}

.feature-column {    
  height: 380px;
}
                          /* ABOUT US */

.aboutus-columns {
  width: 100px;
  height: 100px;
  padding: 2%;
  margin-top: 3%;
  align-self: center;
}
                          /* SERVICES */

.services-text {
  text-align: left;
  font-size: 16px; 
}
.services-image {
  width: 100%;
}

                        /* SERVICES CARD SWAP */
@media screen and (max-width:500px) {
    .textBlock .imageBlock {
      display:flex;
      flex-direction: column-reverse;
    }
    .services-text {
      padding-left: 0;
    }
}

@media screen and (max-width:600px) {
  .textBlock .imageBlock {
    display:flex;
    flex-direction: column-reverse;
  }
  .services-text {
    padding-left: 0;
  }
}

                        /* PRODUCT PAGE  */
.product-boldText{
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 10px;
}

.product-features-icon{
  height: 100px;
  width: 100px;
}
                        /* CONTACT */
.contact-style {
  text-decoration: none;
}

.contact-card {
  padding: 2%;
  height: 20em;
  flex-grow: 1;
  flex-basis: 31%;
  display: flex;
  flex-direction: column;
}